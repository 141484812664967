<template>
  <div class="popup-modal" v-if="visible">
    <div class="popup-content">
      <span class="popup-close" @click="close">&times;</span>
      <div class="login_logo">
        <img src="/ssr/img/tiktok_voice_logo.svg" alt="TikTok AI Voice Generator logo" loading="lazy" height="60px" width="80px" class="top_left_logo"/>
      </div>
      <h2 class="popup-modal-title">{{ data.title }}</h2>
      <p class="popup-modal-desc">{{ data.description }}</p>
      <a :href="pricingHref" class="popup-btn">{{ data.buttonText }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupModal',
  props: {
    data: {
      type: Object,
      required: true
    },
    pricingHref: {
      type: String,
      default: '/en/pricing'
    }
  },
  data() {
    return {
      visible: true
    }
  },
  methods: {
    close() {
      this.visible = false;
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>
